/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    React.useEffect(() => {
        run()
        googleAnalitics()
    }, [])

    let run = () => {
        window.criteo_q = window.criteo_q || []

        var deviceType = /iPad/.test(navigator.userAgent)
            ? "t"
            : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                navigator.userAgent
            )
                ? "m"
                : "d"

        window.criteo_q.push(
            { event: "setAccount", account: 97048 },

            { event: "setSiteType", type: deviceType },

            { event: "viewItem", item: "1" }
        )
    }
    let googleAnalitics = () => {
        ;(function (w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != "dataLayer" ? "&l=" + l : ""
            j.async = true
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
            f.parentNode.insertBefore(j, f)
        })(window, document, "script", "dataLayer", "G-HH5FCXY2EQ")
    }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Built by
          {` `}
          <a href="https://www.hrbotfactory.com">hr bot factory</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
